import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function SuccessPage()
{
  return (
    <Layout>
      <Seo title="Success!" />

      <div className="flex flex-col items-center justify-center space-y-12 h-screen max-w-screen-sm mx-auto px-5">
        <div className="font-medium text-center text-gray-500" style={{ fontSize: '2.2vh' }}>Message successfully sent. Yay!</div>
        <div className="font-medium text-center text-gray-500" style={{ fontSize: '2.2vh' }}>I will get back to you soonest.</div>
        <div className="font-medium text-center text-gray-500" style={{ fontSize: '2.2vh' }}>Back to my <Link to="/" className="font-cursive text-dark-orange text-2xl">Résumé</Link> ?</div>
      </div>

    </Layout>
  )
}

export default SuccessPage